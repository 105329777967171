<template>
  <div id="task-index">
    <div class="route">
      <p class="route-title">
        {{ SelectedInd === 0 ? "任务管理" : "虚拟护栏管理" }}
      </p>
      <router-link v-for="(item, ind) in all_navBar"
                   :key="ind"
                   :to="item.path">
        <div :class="SelectedInd != ind ? 'Selected' : ''"
             @click="Selected(ind)">
          <img :src="SelectedInd != ind ? item.img : item.img_press"
               :alt="item.name" />
          {{ item.name }}
        </div>
      </router-link>
    </div>
    <div class="conent">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      all_navBar: [
        {
          name: '飞行监控',
          img: require('@/assets/img/shangyun/watch1.png'),
          img_press: require('@/assets/img/shangyun/watch.png'),
          path: '/shangyun/live/watch-lists',
        },
        {
          name: '实时直播',
          img: require('@/assets/img/shangyun/live1.png'),
          img_press: require('@/assets/img/shangyun/live.png'),
          path: '/shangyun/live/live-lists',
        },
        {
          name: '云端航线',
          img: require('@/assets/img/shangyun/lane1.png'),
          img_press: require('@/assets/img/shangyun/lane.png'),
          path: '/shangyun/lane/index',
        },
        {
          name: '媒体库',
          img: require('@/assets/img/shangyun/resource1.png'),
          img_press: require('@/assets/img/shangyun/resource.png'),
          path: '/shangyun/resource/media',
        },
      ],
      SelectedInd: 0,
    };
  },

  methods: {
    //选中nav
    Selected(ind) {
      this.SelectedInd = ind;
    },
  },

  updated() {
    let activePath = this.$route.path;
    this.all_navBar.forEach((item, ind) => {
      if (item.path == activePath) {
        this.Selected(ind);
      }
    });
  },
};
</script>

<style  scoped lang="scss">
#task-index {
  width: 100%;
  height: 100%;
  padding: 0.2rem;
  // background-color: #f5f5f5;
  background-color: $xf_hei2;

  display: flex;
  .route {
    font-size: 14px;
    // background-color: $xf_hei2;
    width: 160px;
    height: 100%;
    background-color: $xf_hui1;
    border-right: 1px solid #000;
    font-size: 14px;
    .route-title {
      width: 100%;
      height: 30px;
      line-height: 30px;
      padding-left: 20px;
      background-color: $xf_hui5;
      color: $font_color_1;
    }
    a {
      // display: block;
      // width: 100%;
      color: #4c98ff;
      // margin-top: 21px;
      div {
        display: flex;
        padding-left: 21px;
        align-items: center;
        padding: 15px 10px;
        height: 50px;
        img {
          width: 15px;
          height: 15px;
          margin-right: 11px;
        }
      }
      .Selected {
        color: #888;
        //   background-color: $xf_hui1;
      }
    }

    .add {
      //   position: absolute;
      //   right: 10px;
      padding: 10px 20px;
      button {
        width: 100%;
      }
    }
  }
  .conent {
    width: calc(100% - 160px);
    height: 100%;
    background-color: $xf_hei2;
    overflow-x: auto;
  }
  .conent::-webkit-scrollbar {
    display: none;
  }
}
</style>
